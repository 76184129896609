.mainWrapper {
    display: flex;
    padding: 20px;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

}

.userAddButton {
    background-color: steelblue;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    padding: 0px 20px;
    color: white;
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.userDetCont {
    display: flex;
    min-width: 560px;
    margin: 10px;
    padding: 20px;
  
    border-radius: 5px;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.WorkspacePremiumDet {
    color: rgb(48, 127, 180) !important;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
.detailBarInput{
    border: none;
    font-size: 15px;
    outline: none;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    width: 100% !important;
}
.businessDetCont {
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 5px 0px #000000;
    box-shadow: 0px 0px 5px 0px #000000;
    min-width: 500px;
    min-height: 500px;
}

.detailTitle {
    font-size: 24px;
    font-weight: 600;

}

.detailTitle2 {
    font-size: 16px;
    font-weight: 400;
    color: rgb(181 181 181)
}

.userUpdateForm {
    flex-direction: column;
    margin-top: 10px;
}

.imgCont {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* margin-top: 10px; */
    /* margin-bottom: 20px; */
    /* width: 200px; */
    /* height: 200px; */
    /* border-radius: 10px; */
    /* display: block; */
    margin-left: auto;
    margin-right: auto;

}


.infoContainer {
    padding: 10px;

}

.infoTable {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start; */
    display: flex;
    padding: 7px;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
    margin-top: 10px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    /* color: #8b3939;
    background-color: rgb(255 245 245); */

}




.labelCont {
    font-weight: 600;
    min-width: 170px;
    ;
    font-size: 15px;
    /* margin-bottom: 5px;
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;  */
    /* margin-bottom: 5px;
font-size: 14px; */
}

.userInput {
    /* margin-bottom: 5px; */
    font-size: 15px;
    margin-left: 40px;
    font-weight: 500;
    min-width: 250px;
    border-radius: 4px;
    outline: none;
    border: none;
    /* color: white; */
    /* background-color: rgb(146, 119, 84); */
    /* margin-left: 6px;
 border: none;
 color: black !important;
 background-color: rgb(255 245 245); */
    /* width: 250px;
height: 30px; */
    border-bottom: 1px solid #dbb3b3;
}

.licenseImg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.licenseCont {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    display: block;
    object-fit: cover;
}

.arrow {
    color: rgba(0, 0, 0, 0.3);
    margin-top: 30%;
    width: 60px !important;

}


.licUpCont {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;

}



.licSubmit.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.form-control {
    width: 162px;
    height: 36px;
    margin-left: 20px;
    color: black;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.form-control:hover {
    transform: translate(0, -5px);

}

.licupLabel {
    margin-top: 6px;
    color: rgb(201, 130, 130)
}

/* .imgCont2{
    width: 200px !important;
    height: 200px!important; 
    border-radius: 10px!important;
    display: block!important;
    margin-left: auto!important;
    margin-right: auto!important;  
    object-fit: cover!important;
} */


.blockBtn{
    background-color: steelblue;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    padding: 0px 20px;
    color: white;
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


.unBlockBtn{
    background-color: rgb(180, 70, 70);
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    padding: 0px 20px;
    color: white;
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


.deleteBtn{
    background-color: rgb(24, 131, 86);
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    padding: 0px 20px;
    color: white;
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}