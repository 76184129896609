.sidebar {
  background-color: white !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  background-color: white !important;
}
.sidebarWrapper {
  padding: 10px;
  margin-top: 40px;
  background-color: white !important;
}
.sidebarMenu {
  margin-bottom: 10px;
}
.sidebarTitle {
  font-size: 15px;
  color: #302e2e;
}
.sidebarList {
  list-style: none;
  padding: 5px;
}
.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  border-radius: 12px 0px;
  color: #333333;
}

li.sidebarListItem:hover {
   /* background: linear-gradient(343deg, #cc9898, #e77d7d, #fff, #ff9d9d, #d37373); */
  background-size: 200%;
  border: 1px solid #50BC9E;
  /* animation: shine 2s linear infinite;  */
}

@keyframes shine {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

a.link.active .sidebarListItem {
  /* background-color: #b0b0b0; */
  padding-left: 12px;
  /* border: 1px solid red; */

  margin-top: 3px;
  color: #50BC9E !important;
  margin-bottom: 3px;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
/* a.link.active .sidebarListItem:hover{
    background-color: #50BC9E;
} */
.sidebarIcon {
  margin-right: 15px;
  font-size: 25px !important;
  color:#50BC9E !important;
}
