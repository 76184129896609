/* .MuiButtonBase-root {
  color: rgb(92, 87, 87) !important;
} */

.sidebarListItem a.link {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  color: rgb(83, 83, 83) !important;
}

.searchBar {
  color: #2f323b !important;
}
.model-selector {
  appearance: none;
  padding: 10px;
  width: 100% !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  width: 200px;
}

.model-selector:focus {
  outline: none;
  /* border-color: #007bff; */
  /* box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); */
}

.model-selector option {
  padding: 10px;
}
.MuiInputBase-root,
.MuiTablePagination-actions {
  margin-top: -15px !important;
}
/* DARK MODE */

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.sidebarTitle {
  color: #9a9a9a !important;
}
.sidebar {
  background-color: #2f323b !important;
  /* #be6464 */
}
.css-1k455el {
  background-color: #2f323b !important;
}

.css-1nnrorg-MuiPaper-root-MuiAppBar-root {
  background: linear-gradient(to right, #ffffff 0%, #ffcccc 100%) !important;
}
a.link.active .sidebarListItem {
  color: #2f323b !important;
}
.sidebarListItem:hover {
  color: #2f323b !important;
}

/* ALL FORM CSS  */
.userDetCont {
  display: flex;
  min-width: 560px;
  margin: 60px 10px 0px 10px;
  padding: 20px;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubmitBtnBox {
  display: flex;
  align-items: center;
}

.detailTitle {
  font-size: 24px;
  font-weight: 600;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.updateDataBtn {
  margin: 10px auto 0px auto;
  min-width: 100px;
  background-color: #3bb077;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}

.outerMost {
  padding: 20px;
}

.secondBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
}

.mainBoxx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.headingDiv {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.imGCont {
  padding: 10px;

  margin-top: 40px;
}

.myImg {
  object-fit: cover;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  min-width: 250px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.formContainer {
  flex: 3;
  padding: 40px;
}

.formInMain {
  display: grid;
  grid-template-columns: 1.6fr 3fr;
}

.myLabel {
  padding: 5px;
  margin: 10px;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
}

.myInput {
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  border: none;
  border-bottom: 1px solid #dbb3b3;
}

.smallboxes {
  margin: 10px;
}





.loader {
  margin-top: 300px;
}

a {
  text-decoration: none !important;
  font-size: 18px !important;
  color: black !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  font-size: 15px !important;
  font-weight: 400 !important;
}
.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  font-size: 14px !important;
  font-weight: 300 !important;
  background-color: #10121b !important;
  color: white !important;
}

.newBtn {
  background-color: transparent;
  color: #50BC9E;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: 3px solid #50BC9E;
  padding: 8px 25px;
  font-weight: 600;
  font-family: ui-monospace !important;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.newBtn:hover {
  background-color: #50BC9E;
  color: white;
}
.newBtn:active{
  transform: scale(.96)
}
.errorMessage {
  font-size: 13px;
  font-weight: 600;
  color: white;
  padding: 10px;
  margin: 10px 0;
  background-color: #d0424f;
  border: 1px solid #cccccc;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 3px;
}
.fileErr {
  font-size: 14px;
  color: red;
  text-align: center;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard {
  margin-top: -31px !important;
}

.topBarIcon {
  color: white !important;
  font-size: 30px !important;
}

.popUpCreate {
  background-color: #2b2d31;
  padding: 30px;
}
.popHeading {
  font-size: 22px;
  margin-top: 10px;
  color: white;
  font-family: ui-monospace;
  font-weight: 500;
}
.popText {
  color: lightslategrey;
  font-size: 15px;
  font-family: ui-monospace;
  margin-left: 7px;
  font-weight: 500;
  margin-top: 10px;
}

.popInput {
  width: 250px;
  background: black;
  padding: 11px;
  border-radius: 50px;
  outline: none;
  border: none;
  color: white;
}
.popInput:focus {
  outline: none;
  border: none;
}

.popBtn {
  background: white;
  color: black;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  padding: 8px 25px;
  font-family: ui-monospace !important;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.statusChipPending {
  background-color: #f0ad4e !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.statusChipCompleted {
  background-color: #5cb85c !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.statusChipFailed {
  background-color: #d9534f !important;
  color: white !important;
  border: 1px solid #cccccc !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.errText {
  font-size: 15px;
  color: red;
  margin-top: 5px;
  margin-left: 8px;
}

textarea {
  resize: none;
}
.ql-editor {
  font-size: 18px !important;
}
.textError {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  color: #878787;
  font-weight: 600;
}
.purchaseChip {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  gap: 4px;
  width: 130px;
  padding: 5px 0px 4px 8px;
  background: rgb(228, 228, 228);
  font-size: 11px;
  font-weight: 500;
  color: black;
  border-radius: 30px;
}

.removedUser-Access {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #c52828;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
}

.adminCreation {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  /* background-color: #1886e0; */
  padding: 8px 12px;
  border-radius: 20px;
  gap: 5px;
}

.hiddenTopic {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #c52828;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
}

.newMessage {
  position: relative;
  display: inline-flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: #00a331;
  padding: 8px 10px;
  border-radius: 20px;
  gap: 5px;
  overflow: hidden;
}

.newMessage::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 20px;
  background-color: rgba(0, 163, 49, 0.5);
  opacity: 0;
  animation: glowing 2s ease-in-out infinite;
}

@keyframes glowing {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.featuredPostStatus {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to left, #006600 0%, #339933 100%);
  padding: 6px 10px;
  border-radius: 20px;
  gap: 5px;
}
.normalPostStatus {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to left, #0099cc 0%, #0066cc 100%);
  padding: 7px 10px;
  border-radius: 20px;
  gap: 5px;
}

.detailPageImage {
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
}

textarea .textareaField {
  width: 100%;
  border: none;
  border-bottom: 1px ridge darkgray;
}

.screenHeading {
  background: #e9e9e9;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  border-radius: 11px;
}

.stepsButton{ 
  font-weight: 400;
    padding: 0px 15px;
    color: white;
    background-color: #ca3c3c;
    outline: none;
    border: 2px solid black;
}

.videosChapter{
  display: flex;
  margin-bottom: 7px;
  padding: 5px;
  border-radius: 2px;
  background-color: rgb(43, 42, 42);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  /* transition: background-color 0.3s ease; */

}
.videosChapter:hover{
  border: 1px solid rgb(187, 187, 187);
}


/* CSS classes for selected chapter effect */
.selectedChapter {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: black;
  transform: scale(1.03);
  animation: shineBorder 1s ease-in-out;
}

.reactPlayer{
  object-fit: fill !important;
}

@keyframes shineBorder {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.945);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(94, 90, 90, 0);
  }
}




