@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.fill_center * {
	box-sizing: border-box;
}

.fill_center {
	/* background: #f6f5f7; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 104vh;
	/* margin: -20px 0 50px; */
	  /* background-image: url('/images/background.jpg'); */
  /* You can specify other background properties like background-size, background-repeat, etc. */
  background-size: cover;
  background-repeat: no-repeat;
}

.fill_center h1 {
	font-weight: bold;
	/* margin: 0; */
}

.fill_center h2 {
	text-align: center  ;
}
.message {
    color: #3ec13e;
    font-size: 22px;
    font-weight: 500;
}
.paraMessage{
    font-size: 16px;
    color: black;
    font-weight: 700;
}
.fill_center p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.fill_center span {
	font-size: 12px;
}

.fill_center a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.fill_center button {
	border-radius: 20px;
	/* border: 1px solid #50BC9E; */
	border: none;
	background-color: #737373;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.fill_center button.active {
	transform: scale(0.95);
}

.fill_center button:focus {
	outline: none;

}

.fill_center button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}
.fill_center button.ghost:hover {
	background-color: transparent;
	border-color: #FFFFFF;
	border: 1px solid red;

}

.fill_center form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}
.heading2{
	margin-bottom: 20px !important;
    font-weight: 300;
    margin-top: 16px;
	font-size: 30px;
    color: #50BC9E;
}
.fill_center input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    border-radius: 5px;
    margin: 12px 0;
    width: 100%;
}

.heading1 {
    margin-bottom: 20px;
    font-weight: 400;
	font-size: 30px;
    margin-top: 16px;
    color: #5f4c50;
}
.submitButton{
	margin-top: 17px;
}
.container1 {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.loginLogo {
    height: 96px;
	border-radius: 20px;
	width: 80%;
    object-fit: contain;
    /* margin-bottom: 32px; */
}
.fill_center p{
	color: #dddbe1;
}
.singinButton{
	margin-top: 17px;
}
.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container1.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container1.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container1.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #50BC9E;
	/* background: -webkit-linear-gradient(to right, #50BC9E, #50BC9E); */
	background: linear-gradient(to right, #1d1d1d, #4f4d4d);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container1.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container1.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container1.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.fill_center footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

.fill_center footer p {
    margin: 10px 0;
}

.fill_center footer i {
    color: red;
}

.fill_center footer a {
    color: #3c97bf;
    text-decoration: none;
}