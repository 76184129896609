.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
  }
  
  .paper {
    margin-top: 32px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    width: 700px;
  }
  
  .form {
    width: 100%;
    margin-top: 8px;
  }
  
  
  
  .submit {
    margin-top: 24px;
  }
  